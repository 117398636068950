<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Empirical Formula - Report Question 1</h2>

      <p class="mb-n1">
        What is the purpose of the crucible lid in this experimental procedure? Choose the best
        option of the four shown here.
      </p>

      <v-radio-group v-model="inputs.reasonForLid" class="ml-6 mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsForLid"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OleMissLab7ReportQuestion1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        reasonForLid: null,
      },
      optionsForLid: [
        {
          text: 'To control the rate of the Mg combustion reaction',
          value: 'limitRate',
        },
        {
          text: 'To maintain the hazardous gas evolved during the reaction to a safe level',
          value: 'gas',
        },
        {
          text: 'To prevent the splattering of molten metal',
          value: 'preventSplatter',
        },
        {
          text: 'To prevent the student from seeing the white light of the burning Mg and prevent eye damage',
          value: 'shieldLight',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
